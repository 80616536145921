<template>
    <section>
        <div class="float d-flex justify-content-end">
            <button class="btn btn-float btn-rounded btn-icon btn-secondary" @click="scrollToTop()"><i class="fa fa-level-up"></i></button>
        </div>
    </section>
</template>

<script>
export default {
    name: "float-go-top",
    data () {
        return {
            details: false
        }
    },
    computed: {
    },
    methods:{
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
    mounted(){
    }
}
</script>
<style scoped>
.float {
    position: fixed;
    width: 100%;
    max-width: 20rem;
    height: auto;
    bottom: 0vh;
    right: 0vh;
    z-index: 3001;
}
.btn-float {
    background: #d4b54ea8;
    position: fixed;
    bottom: 1vh;
    right:1vh;
    z-index: 3000;
}
</style>